import React, { Component } from 'react';

import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import GetAppIcon from '@material-ui/icons/GetApp';

import { withStyles } from '@material-ui/core/styles';
// import Slide from '@material-ui/core/Slide';
// import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
// import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import DeviceCapture from '../Screens/DeviceCapture';
import ProgressIndicator from './ProgressIndicator';

import {
  HandleDownloadCaptureAgent,
} from '../Util/api';

const styles = theme => ({
  content: {
    height: "100%",
    overflow:"auto",
    position:"relative",
  },
});

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="right" ref={ref} {...props} />;
// });

class DeviceCaptureDialog extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      open: props.open,
      BeginScanNewKey: new Date(),
      ShowProgressIndicator: false,
    }
  }

  handleBeginScanNew = () => {
    this.setState({BeginScanNewKey: new Date()});
  }

  handleClose = (moveBack, moveForward) => {
    this.setState({ open: false });
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  componentDidUpdate(prevProps) {
    if (typeof this.props.open !== "undefined"
      && prevProps.open !== this.props.open) {
      this.setState({open: this.props.open !== false});
    }
  }

  render() {
    const { 
      open,
      BeginScanNewKey,
      ShowProgressIndicator,
  	} = this.state;
    const {
      organizationId,
      projectId,
      fieldIDsAndValues,
      taskId,
      approvalId,
      assetId,
      assetItemId,
      formTemplate,
      formTemplateFieldId,
      skipFinalization,
      closeAfterFirstSubmit,
      onComplete,
      completionMessage,
      documentId,
      isAppend,
      documentNameForAppend,
      documentFolderId,
      documentFolderName,
      isWorkspace,
      classes,
      theme,
      onApiError,
      onAlert,
      ...restProps
    } = this.props;

    // console.log("deviceCaptureDialog", documentFolderId, approvalId, taskId, assetId, assetItemId);

    let progressIndicator = null;
    if (ShowProgressIndicator) {
      progressIndicator = (
        <ProgressIndicator />
      );
    }

    // let dialogActions = (
    //   <DialogActions>
    //     <Button onClick={() => this.handleClose()}>
    //       CLOSE
    //     </Button>
    //   </DialogActions>
    // );

    const content = (
      <div style={{
        width:"calc(100% - 2px)",
        height:"calc(100% - 2px)",
        border:"1px solid",
        borderColor:theme.palette.divider,
      }}>
        <DeviceCapture
          {...restProps}
          organizationId={organizationId}
          projectId={projectId}
          fieldIDsAndValues={fieldIDsAndValues}
          taskId={taskId}
          documentId={documentId}
          approvalId={approvalId}
          assetId={assetId}
          assetItemId={assetItemId}
          formTemplate={formTemplate}
          formTemplateFieldId={formTemplateFieldId}
          skipFinalization={skipFinalization}
          closeAfterFirstSubmit={closeAfterFirstSubmit}
          onComplete={onComplete}
          completionMessage={completionMessage}
          onClose={this.handleClose}
          documentFolderId={documentFolderId}
          isWorkspace={isWorkspace}
          isAppend={isAppend}
          onApiError={onApiError}
          onAlert={onAlert}
          beginScanNewKey={BeginScanNewKey}
          returnContentOnly
        />
      </div>
    );

    const title = (isAppend && documentNameForAppend)
      ? `Append to ${documentNameForAppend}`
      : `Scan document(s)${(documentFolderName) ? " in folder " + documentFolderName : ""}`;

    const dialogTitleDiv = (
      <div style={{
        display:"flex",
      }}>
        <div style={{
            flexGrow:1,
          }}
        >
          {title}
        </div>
        <Tooltip title="Scan new images">
          <IconButton
            edge="end"
            color="inherit" aria-label="Scan new"
            style={{
              marginRight:theme.spacing(1),
            }}
            onClick={() => this.handleBeginScanNew()}>
            <AddIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Download Capture Agent">
          <IconButton
            edge="end"
            color="inherit" aria-label="Download Capture Agent"
            style={{
              marginRight:theme.spacing(1),
            }}
            onClick={() => HandleDownloadCaptureAgent(onApiError)}>
            <GetAppIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Close dialog">
          <IconButton
            edge="end"
            color="inherit" aria-label="Close dialog"
            onClick={() => this.handleClose()}>
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </div>
    );

    return (
      <Dialog
        fullScreen
        // TransitionComponent={Transition}
        // PaperProps={{
        //   style:{
        //     minHeight:"50%",
        //   }
        // }}
        open={open}
        // Disable blur close (ESC or click away) to prevent losing uploads - User must click CLOSE
        // onClose={() => this.handleClose()}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description">
        <DialogTitle id="dialog-title">
          {dialogTitleDiv}
        </DialogTitle>
        <DialogContent
          style={{
            paddingTop:0,
            marginBottom:theme.spacing(2),
          }}
        >
          {progressIndicator}

          {content}
        </DialogContent>

        {/*{dialogActions}*/}
      </Dialog>
    );
  }
}

DeviceCaptureDialog.propTypes = {
  open: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  organizationId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  fieldIDsAndValues: PropTypes.object,
  documentId: PropTypes.string,
  taskId: PropTypes.string,
  approvalId: PropTypes.string,
  assetId: PropTypes.string,
  assetItemId: PropTypes.string,
  formTemplate: PropTypes.object,
  formTemplateFieldId: PropTypes.string,
  skipFinalization: PropTypes.bool,
  closeAfterFirstSubmit: PropTypes.bool,
  onComplete: PropTypes.func,
  completionMessage: PropTypes.string,
  documentFolderId: PropTypes.string,
  documentFolderName: PropTypes.string,
  isWorkspace: PropTypes.bool,
  isAppend: PropTypes.bool,
  documentNameForAppend: PropTypes.string,
  onApiError: PropTypes.func.isRequired,
  onAlert: PropTypes.func.isRequired,
}

export default withStyles(styles, {withTheme: true})(DeviceCaptureDialog);