import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import Checkbox from '@material-ui/core/Checkbox';
import LinearProgress from '@material-ui/core/LinearProgress';
import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';

import red from '@material-ui/core/colors/red';

import { DragSource, DropTarget } from 'react-dnd';
import { IsMobile } from '../Util/MobileDetector';
import { GetImageComponent } from '../Util/Image';
import ImageActionType from '../Model/ImageActionType';

/**
 * Implements the drag source contract.
 */
const imageCardSource = {
  canDrag(props) {
    return !IsMobile();
  },
  beginDrag(props, monitor, component) {
    props.onStartMoveImageCard();
    component.hideActions();
    return {
      Image: props.Image,
      onDropImageCard: props.onDropImageCard,
    };
  },
  endDrag(props, monitor, component) {
    if (!monitor.didDrop()) {
      props.onAbortMoveImageCard();
    }
    props.onEndMoveImageCard();
    component.showActions();
  }
};

/**
 * Specifies the drop target contract.
 * All methods are optional.
 */
const imageCardTarget = {
  drop(props, monitor, component) {
    if (monitor.didDrop()) {
    //   // If you want, you can check whether some nested
    //   // target already handled drop
      return;
    }

    // Obtain the dragged item
    const sourceImageCard = monitor.getItem();
    sourceImageCard.onDropImageCard();

    // You can also do nothing and return a drop result,
    // which will be available as monitor.getDropResult()
    // in the drag source's endDrag() method
    //return { moved: true };
  },
  hover(props, monitor, component) {
    if (!component)
      return null;
    
    // component.hideActions();
    // component.markForActionsVisibilityResetOnDragEnd();

    // Obtain the dragged item  
    const sourceImageCard = monitor.getItem();
    const dragRank = sourceImageCard.Image.Rank;
    const hoverRank = props.Image.Rank;

    // Don't replace items with themselves
    if (dragRank === hoverRank)
      return;

    props.onMoveImageCard(sourceImageCard.Image);
  },
}

/**
 * Specifies the props to inject into your component.
 */
function dragCollect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  };
}

/**
 * Specifies which props to inject into your component.
 */
function dropCollect(connect, monitor) {
  return {
    // Call this function inside render()
    // to let React DnD handle the drag events:
    connectDropTarget: connect.dropTarget(),
    // You can ask the monitor about the current drag state:
    // isOver: monitor.isOver(),
    // isOverCurrent: monitor.isOver({ shallow: true }),
    // canDrop: monitor.canDrop(),
    // itemType: monitor.getItemType()
  };
}

const _cardMaxHeight = 600;
const _cardActionsContainerHeightBase = 32;
const _cardActionsContainerHeightFull = _cardActionsContainerHeightBase + 16;
const styles = theme => ({
  card: {
    "&:hover $cardActionsContainer": { 
      display:"flex",
    },
    "&:hover $cardActionsHover": {
      display:"flex",
    },
    "&:hover $floatingActionsHover": {
      display:"flex",
    },
  },
  cardActionArea: {
    // height:210,
    // "&:hover $cardActionArea_focusHighlight": {
    //   opacity:0.25,
    // },
  },
  // cardActionArea_focusHighlight: {
  // },
  cardContent: {
    cursor:"grabbing",
    padding:0,
    minHeight:1.8 * _cardActionsContainerHeightFull,
    maxHeight:_cardMaxHeight,
    overflow: "hidden",
    position:"relative",
  },
  cardActionsContainer: {
    position:"sticky",
    bottom:0,
    left:0,
    height: _cardActionsContainerHeightFull,
    marginBottom:-1 * _cardActionsContainerHeightFull,
    display:"none",
    backgroundColor:theme.palette.background.cardActionBar,
    overflow:"hidden",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  cardSelected: {
    display:"flex",
  },
  cardActionsStatic: {
    margin:0,
    flexGrow: 1,
  },
  cardActionsHover: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    display: "none",
  },
  floatingActionsHover: {
    position:"absolute",
    left:theme.spacing(1) + 4,
    top:theme.spacing(1),
    display: "none",
  },
  uploadProgress: {
    position:"absolute",
    left:theme.spacing(2),
    bottom:theme.spacing(2),
    width:`calc(100% - ${theme.spacing(4)}px)`,
    backgroundColor: "#ddd",
  },
});

class ImageCard extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      dimensions: null,
      hideActions: false,
    }

    this.containerRef = null;
  }

  handleCardActionAreaClick = e => {
    if (e.ctrlKey) {
      this.props.onSelect();
    } else {
      this.props.onCardAction();
    }
  }

  handleCardActionsClick = e => {
    if (e.ctrlKey) {
      this.props.onSelect();
    }
  }

  hideActions = () => {
    this.setState({ hideActions: true });
  }

  showActions = () => {
    this.setState({ hideActions: false });
  }

  markForActionsVisibilityResetOnDragEnd = () => {
    // if (this.props.onAddEndMoveCallback) {
    //   this.props.onAddEndMoveCallback(this.showActions);
    // }
  }

  reRender = () => {
    this.setState({
      dimensions: null,
    });
  }

  componentDidMount() {
    window.addEventListener('resize', this.reRender);

    this.setState({
      dimensions: {
        width: this.containerRef.offsetWidth,
        height: this.containerRef.offsetHeight,
      },
    });
  }

  componentDidUpdate(prevProps) {
    if (!this.state.dimensions) {
      this.setState({
        dimensions: {
          width: this.containerRef.offsetWidth,
          height: this.containerRef.offsetHeight,
        },
      });
    }
    if (this.props.reRenderDate !== prevProps.reRenderDate) {
      setTimeout(this.reRender, 250);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.reRender);
    if (this.containerRef && this.containerRef.parentElement) {
      this.containerRef.parentElement.removeEventListener('resize', this.reRender);
    }
  }

  render() {
    const {
      dimensions,
    } = this.state;

    return (
      <div ref={instance => (this.containerRef = instance)}>
        {dimensions && this.renderContent()}
      </div>
    );
  }

  // We render content this way to grab the dimensions of the parent,
  // which is _absolutely_ necessary for ensuring the width of the image.
  renderContent() {
    const {
      dimensions,
      hideActions,
    } = this.state;
    const { 
      classes,
      // theme,
      noMargin,
      style,
      Image,
      connectDragSource,
      connectDropTarget,
      isDragging,
      // isOver,
      onSelect,
      selected,
      onAction,
      index,
      isAppend,
    } = this.props;

    let docBreakIconButton = (!isAppend) ? (
      <IconButton
        size="small"
        aria-label={ImageActionType.DocBreak.Label}
        title={ImageActionType.DocBreak.Label}
        onClick={e => { e.stopPropagation(); onAction(ImageActionType.DocBreak); }}
      >
        {ImageActionType.DocBreak.Icon}
      </IconButton>
    ) : null;

    // let floatingActionsHover = (!hideActions) ? (
    //   <div className={classes.floatingActionsHover}>
    //     {docBreakIconButton}
    //   </div>
    // ) : null;

    let floatingUploadProgress = (Image.IsUploading)
      ? ( <LinearProgress
          className={classes.uploadProgress}
          color="primary"
          variant="determinate"
          value={Image.UploadProgressPercentComplete} />
      ) : null;

    let cardContent = (
      <CardContent className={classes.cardContent}>
        {GetImageComponent(Image, dimensions.width, _cardMaxHeight)}
        {floatingUploadProgress}
        {/*floatingActionsHover*/}
      </CardContent>
    );

    let cardActionArea = (
      <CardActionArea
        component="div"
        classes={{
          root: classes.cardActionArea,
          // focusHighlight: classes.cardActionArea_focusHighlight,  
        }}
        disableRipple
        onClick={this.handleCardActionAreaClick}>
        {cardContent}
      </CardActionArea>
    );

    let rotateLeftIconButton = (
      <IconButton
        size="small"
        aria-label={ImageActionType.RotateLeft.Label}
        title={ImageActionType.RotateLeft.Label}
        onClick={() => onAction(ImageActionType.RotateLeft)}
      >
        {ImageActionType.RotateLeft.Icon}
      </IconButton>
    );
    let rotateRightIconButton = (
      <IconButton
        size="small"
        aria-label={ImageActionType.RotateRight.Label}
        title={ImageActionType.RotateRight.Label}
        onClick={() => onAction(ImageActionType.RotateRight)}
      >
        {ImageActionType.RotateRight.Icon}
      </IconButton>
    );
    let zoomInIconButton = (
      <IconButton
        size="small"
        aria-label={ImageActionType.ZoomIn.Label}
        title={ImageActionType.ZoomIn.Label}
        onClick={() => onAction(ImageActionType.ZoomIn)}
      >
        {ImageActionType.ZoomIn.Icon}
      </IconButton>
    );

    let cardActionsContainerClassNames = classNames(classes.cardActionsContainer);
    if (selected) {
      cardActionsContainerClassNames = classNames(cardActionsContainerClassNames, classes.cardSelected);
    }
    let cardActions = (!hideActions) ? (
      <CardActions className={cardActionsContainerClassNames}
        onClick={this.handleCardActionsClick}
        // style={{ opacity: (isDragging) && 0 }}
        >
        <div className={classes.cardActionsStatic}>
          <Checkbox
            color="secondary"
            style={{ marginRight:-10 }}
            size="small"
            checked={selected}
            onClick={onSelect} />
        </div>
        <div className={classes.cardActionsHover}>
          {docBreakIconButton}
          {rotateLeftIconButton}
          {rotateRightIconButton}
          {zoomInIconButton}
        </div>
      </CardActions>
    ): null;

    return (
      <Card className={classes.card}
        ref={instance => connectDropTarget(connectDragSource(ReactDOM.findDOMNode(instance)))}
        style={{
          margin: (noMargin) ? 0 : "",
          opacity: (isDragging) && 0,
          boxShadow: (index === 0 || Image.IsDocumentBreak) ? `0px 0px 2px 2px ${red[500]}` : null,
          ...style
        }}>
        {cardActionArea}
        {cardActions}
      </Card>
    );
  }
}

ImageCard.propTypes = {
  classes: PropTypes.object.isRequired,
  reRenderDate: PropTypes.object,
  // Injected by React DnD:
  isDragging: PropTypes.bool.isRequired,
  // isOver: PropTypes.bool.isRequired,
  connectDragSource: PropTypes.func.isRequired,
  connectDropTarget: PropTypes.func.isRequired,
  isAppend: PropTypes.bool,
};

export default DropTarget('ImageCard', imageCardTarget, dropCollect)(DragSource('ImageCard', imageCardSource, dragCollect)(withStyles(styles, {withTheme: true})(ImageCard)));