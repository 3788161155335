import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';
import Fab from '@material-ui/core/Fab';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import AddIcon from '@material-ui/icons/Add';

import ImageCard from '../Components/ImageCard';
import UiCore from '../Components/UiCore';
import ImageActionType from '../Model/ImageActionType';
import MultiUseDialog from '../Components/MultiUseDialog';
import DeviceCaptureControlDialog from '../Components/DeviceCaptureControlDialog';
import { GetImageComponent } from '../Util/Image';
import ReRankItemInCollection from '../Util/ItemRanking';
import ActionDrawer from '../Components/ActionDrawer';

import API, {
  GetUploadFilePromise,
  HandleDownloadCaptureAgent,
} from '../Util/api';
import axios from 'axios';
import { TestConnection } from '../Util/twainCaptureApi';
import { NumberWithSeparators } from '../Util/NumberFormatting';
import ProgressIndicator from '../Components/ProgressIndicator';

import {
  GetImageUploadSessionImagesPathForApi,
  GetImageUploadSessionsPathForApi,
  GetUserOrganizationProjectDocumentFolderImageUploadSessionImagesPathForApi,
  GetUserOrganizationProjectDocumentFolderImageUploadSessionsPathForApi,
  GetUserOrganizationProjectTaskImageUploadSessionsPathForApi,
  GetUserOrganizationProjectApprovalTaskImageUploadSessionsPathForApi,
  GetUserOrganizationProjectApprovalAssetItemTaskImageUploadSessionsPathForApi,
  GetUserOrganizationProjectTaskImageUploadSessionImagesPathForApi,
  GetUserOrganizationProjectApprovalTaskImageUploadSessionImagesPathForApi,
  GetUserOrganizationProjectApprovalAssetItemTaskImageUploadSessionImagesPathForApi,
  GetUserOrganizationProjectApprovalAssetItemImageUploadSessionsPathForApi,
  GetUserOrganizationProjectApprovalAssetItemImageUploadSessionImagesPathForApi,
  GetFormTemplatesPublicFieldImageUploadSessionsPathForApi,
  GetFormTemplatesPublicFieldImageUploadSessionImagesPathForApi,
} from '../Util/api';

const toolHeaderHeight = 48;
const styles = theme => ({
  root: {
    height: "100%",
    overflowX:"hidden",
    position:"relative",
    display:"flex",
  },
  toolHeaderWithContent: {
    width:"100%",
  },
  toolHeader: {
    position:"sticky",
    top:0,
    left:0,
    height:toolHeaderHeight,
    paddingLeft:theme.spacing(3),
    paddingRight:19,
    backgroundColor:theme.palette.background.pane,
    borderBottom:"1px solid",
    borderBottomColor:theme.palette.divider,
    zIndex:1,
    display:"flex",
    justifyContent: "flex-end",
  },
  toolButtons: {
    flexGrow:1,
    display:"flex",
    alignItems:"center",
  },
  infoGrid: {
    marginRight:theme.spacing(1),
    "& div": {
      whiteSpace: "nowrap",
    },
  },
  toolInfo: {
    display:"flex",
    alignItems: "center",
  },
  content: {
    height:`calc(100% - ${toolHeaderHeight+1}px)`,
    position:"relative",
    display:"flex",
    overflowY:"auto",
    overflowX:"hidden",
  },
  imageGridContainer: {
    width:"100%",
    overflow:"auto",
    padding:theme.spacing(3),
    paddingTop:theme.spacing(1),
  },
  fab: {
    position: "absolute",
    zIndex: 1,
    right: theme.spacing(3),
    bottom: theme.spacing(2),
  },
  docHeader: {
    marginBottom:-theme.spacing(1),
  },
});

const rankIncrement = 1000;

class DeviceCapture extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      ImageUploadSession: null,
      Images:[],
      ImageCardReRenderDate: new Date(),
      PreMoveImagesJson: "",
      SelectedImageIds: [],
      ShowDialogProgressIndicator: false,
      ShowDeleteImagesConfirmation: false,
      ShowProgressIndicator: false,
      ShowProgressIndicatorImmediately: false,
      ShowImageDialog: false,
      ShowImageDialog_Image: null,
      ShowDeviceCaptureControlDialog: false,
      ShowAwaitingUploadCompletionDialog: false,
      ShowConnectionIssueDialog: false,
    }

    this.UploadHandlerActive = false;
    this.UploadHandlerReloadID = null;
    this.FinalizeImageUploadSessionID = null;
    this.CancelToken = null;
    this.EndMoveCallbacks = [];
    this.AwaitingUploadCompletionDialogClosedByUser = false;
  }

  handleStartMoveImageCard = () => {
    // Generate rank if the first two have equal rank
    let images = [...this.state.Images];
    let stateToUpdate = { PreMoveImagesJson: JSON.stringify(images) }
    if (images.length > 1 
      && images[0].Rank === images[1].Rank) {
      for (let i = 0; i < images.length; i++) {
        images[i].Rank = i * rankIncrement;
      }
      stateToUpdate.Images = images;
    }

    this.setState(stateToUpdate);
  }

  handleEndMoveImageCard = () => {
    // if (this.EndMoveCallbacks.length) {
    //   setTimeout(() => {
    //     this.EndMoveCallbacks.forEach(callback => callback());
    //     this.EndMoveCallbacks = [];
    //   });
    // }
  }

  handleAddEndMoveCallback = callback => {
    this.EndMoveCallbacks.push(callback);
  }

  handleMoveImageCard(sourceImage, targetImage) {
    if (!sourceImage) {
      return;
    }
    // console.log("Source Image: ", sourceImage.ID);
    
    if (this.state.ImageIsMoving) {
      return;
    }
    // this.setState({ImageIsMoving: true});

    // Target is ImageCard
    if (targetImage) {
      // console.log("Target Image: ", targetImage.ID);
      if (sourceImage.Rank === targetImage.Rank)
        return;

      let images = [...this.state.Images];
      let sourceIndex = images.indexOf(sourceImage);
      let targetIndex = images.indexOf(targetImage);
      if (sourceIndex === targetIndex)
        return;

      // console.log("Index of source: ", sourceIndex, " Index of target: ", targetIndex);
      if (sourceIndex === null || targetIndex === null)
        return;

      images.splice(sourceIndex, 1);
      images.splice(targetIndex, 0, sourceImage);
      this.setState({ Images: images });
    }
  }

  handleAbortMoveImageCard = () => {
    let images = [...this.state.Images];
    images = JSON.parse(this.state.PreMoveImagesJson)
    this.setState({ Images: images });
  }

  handleDropImageCard = image => {
    if (!image)
      return;
    this.reRankImageCard(image);
  }

  reRankImageCard(image) {
     let { 
      Collection: reRankedImages, 
      StartIndex, 
      EndIndex,
    } = ReRankItemInCollection(image, [...this.state.Images], rankIncrement);    
    this.setState({ Images: reRankedImages});

    // Build array of changed items to send to server
    let preMoveImages = JSON.parse(this.state.PreMoveImagesJson);
    let updatedImages = [];
    for (let i = StartIndex; i <= EndIndex; i++) {
      let image = reRankedImages[i];
      let filtered = preMoveImages.filter(p => p.ID === image.ID)
      if (filtered.length > 0) {
        let preMoveImage = filtered[0];
        // console.log("original: ", preMoveImage.Rank, " new: ", image.Rank);
        if (image.Rank !== preMoveImage.Rank) {
          updatedImages = updatedImages.concat({
            ID: image.ID,
            Rank: image.Rank,
          });
        }
      }
    }

    // Send changed items to server
    // this.handleUpdateImages(updatedImages, true);
  }

  handleAction = (actionType, imageId_optional) => {
    let selectedImageIds = (imageId_optional)
      ? [imageId_optional]
      : [...this.state.SelectedImageIds];
    if (selectedImageIds.length === 0) {
      return;
    }
    this.setState({ShowProgressIndicatorImmediately: true});
    let actionFinalizer = () => {
      this.setState({ShowProgressIndicatorImmediately: false});
    }
    switch (actionType) {
      case ImageActionType.RotateLeft:
        this.runFunctionForAllSelectedImages(selectedImageIds,
          image => {
            image.RotationRequested -= 90;
          }, actionFinalizer);
      break;
      case ImageActionType.RotateRight:
        this.runFunctionForAllSelectedImages(selectedImageIds,
          image => {
            image.RotationRequested += 90;
          }, actionFinalizer);
      break;
      case ImageActionType.Delete:
        this.handleSetDeleteImageConfirmationVisibility(true);
        actionFinalizer();
      break;
      case ImageActionType.DocBreak:
        this.runFunctionForAllSelectedImages(selectedImageIds,
          image => {
            image.IsDocumentBreak = !image.IsDocumentBreak;
          }, actionFinalizer);
      break;
      case ImageActionType.ZoomIn:
        this.runFunctionForAllSelectedImages(selectedImageIds,
          image => {
            this.setState({ShowImageDialog_Image: image});
          }, actionFinalizer);
        this.handleSetShowImageDialogVisibility(true);
      break;
      default:
      break;
    }
  }

  runFunctionForAllSelectedImages = (selectedImageIds, f, actionFinalizer) => {
    let images = [...this.state.Images];
    selectedImageIds.forEach(sii => {
      let imageFinder = images.filter(i => i.ID === sii);
      if (imageFinder.length) {
        f(imageFinder[0]);
      }
    });
    this.setState({Images: images});
    actionFinalizer();
  }

  handleDeleteImages = () => {
    this.handleSetDeleteImageConfirmationVisibility(false);
    this.setState({ ShowProgressIndicator: true, })
    let newImages = [];
    this.state.Images.forEach(i => {
      if (!this.state.SelectedImageIds.filter(sii => sii === i.ID).length) {
        newImages.push(i);
      }
    });
    this.setState({
      Images: newImages,
      SelectedImageIds: [],
      ShowProgressIndicator: false,
    });
  }

  handleSelectImage = (imageId) => {
    let selectedImageIds = [...this.state.SelectedImageIds];
    const selectedIndex = selectedImageIds.indexOf(imageId);
    if (selectedIndex === -1) {
      selectedImageIds.push(imageId);
    } else if (selectedIndex > -1) {
      selectedImageIds.splice(selectedIndex, 1);
    }
    this.setState({ SelectedImageIds: selectedImageIds });
  }

  handleSelectAllImages = (event) => {
    let selectedImageIds = [...this.state.SelectedImageIds];
    let images = [...this.state.Images];
    if (!event.target.checked) {
      selectedImageIds = [];
    } else {
      selectedImageIds = images.map(i => i.ID);
    }
    this.setState({ SelectedImageIds: selectedImageIds });
  }

  handleSetDeleteImageConfirmationVisibility = visible => {
    this.setState({
      ShowDeleteImagesConfirmation: visible,
      ShowDialogProgressIndicator: false,
    });
  }

  handleSetShowAwaitingUploadCompletionDialogVisibility = visible => {
    this.setState({
      ShowAwaitingUploadCompletionDialog: visible,
      ShowDialogProgressIndicator: false,
    });
  }

  handleSetShowImageDialogVisibility = visible => {
    this.setState({
      ShowImageDialog: visible,
      ShowDialogProgressIndicator: false,
    });
  }

  handleSetShowDeviceCaptureControlDialogVisibility = visible => {
    this.setState({
      ShowDeviceCaptureControlDialog: visible,
    });
  }

  handleSetShowConnectionIssueDialogVisibility = visible => {
    this.setState({
      ShowConnectionIssueDialog: visible,
    });
  }

  handleDownloadCaptureAgent = () => {
    HandleDownloadCaptureAgent(this.handleApiError, () => {
      this.handleSetShowConnectionIssueDialogVisibility(false);
    });
  }

  testDeviceConnection = () => {
    return TestConnection()
      .catch(err => {
        this.handleSetShowConnectionIssueDialogVisibility(true);
        return Promise.reject();
      });
  }

  handleBeginScanNew = () => {
    this.setState({ShowProgressIndicator: true});
    this.testDeviceConnection()
      .then(() => {
        this.setState({ShowProgressIndicator: false});
        this.handleSetShowDeviceCaptureControlDialogVisibility(true);    
      })
      .catch(err => {
        this.setState({ShowProgressIndicator: false});
      });
  }

  handleAddImage = image => {
    // console.log("Add image", image.ID);
    // The first image of this session is always a document break
    if (!this.state.Images.length) {
      image.IsDocumentBreak = true;
    }
    this.setState({ Images: [...this.state.Images].concat(image) });
    this.tryUploadImages();
  }

  base64toFile = (base64, contentType, sliceSize) => {
    contentType = contentType || '';
    sliceSize = sliceSize || 16384;

    var byteCharacters = atob(base64);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var file = new Blob(byteArrays, {type: contentType});
    return file;
  }

  tryUploadImages = async fromTimeout => {
    if (this.UploadHandlerReloadID === "ABORT") {
      this.UploadHandlerReloadID = null;
      this.UploadHandlerActive = false;
      return;
    }
    if (!fromTimeout && this.UploadHandlerActive) {
      return;
    }
    
    this.UploadHandlerActive = true;
    let imageUploadSessionId = null;
    if (this.state.ImageUploadSession && this.state.ImageUploadSession.ID) {
      imageUploadSessionId = this.state.ImageUploadSession.ID;
    } else {
      await this.handleGetNewImageUploadSession()
        .then(imageUploadSession => imageUploadSessionId = imageUploadSession.ID)
        .catch(err => {
          this.handleApiError(err);
          return;
        });
    }
    const imageUploadUri = this.getImageUploadUri(imageUploadSessionId);

    let images = this.state.Images;
    if (images && images.length) {
      // array.forEach does not act as expected with async/await
      // images.forEach(async i => {
      // console.log("Begin image loop for upload", images.length);
      for(let x = 0; x < images.length; x++) {
        let i = images[x];
        if (!i.IsUploaded && !i.IsUploading) {
          // console.log("Upload image", i.ID);
          i.IsUploading = true;
          this.updateImageState(i);

          let onUploadProgress = (fileName, totalSize, completedSize, completedAtServer) => {
            i.UploadProgressPercentComplete = 100 * ((1 + completedSize) / totalSize);
            this.updateImageState(i);
          }

          const imageUploadParams = {
            uniqueId: (this.props.formTemplate) ? this.props.formTemplate.UniqueID : undefined,
            taskId: (this.props.taskId) ? this.props.taskId : undefined,
            assetId: (this.props.assetId) ? this.props.assetId : undefined,
            assetItemId: (this.props.assetItemId) ? this.props.assetItemId : undefined,
          };

          let onCompletedFileHandler = async (file, image) => {
            i.ObjectName = image.ObjectName;
            i.IsUploading = false;
            i.IsUploaded = true;
            this.updateImageState(i);

            return await API.post(imageUploadUri, [this.getImageUploadObjectWithoutImageData(i)], { params: imageUploadParams })
              .then(resp => {
                // console.log("Completed image", i.ID, resp.data.ImageUploadSessionImageID);
                i.ImageUploadSessionImageID = resp.data[0].ImageUploadSessionImageID;
                this.updateImageState(i);
                return { resp };
              })
              .catch(err => { return { err }; });
          }

          await GetUploadFilePromise(this.base64toFile(i.Base64, i.ContentType), imageUploadUri, imageUploadParams,
            this.CancelToken, onUploadProgress, onCompletedFileHandler)
            .catch(err => {
              if (axios.isCancel(err)) {
                // console.log("Image uploading canceled", i.ID);
                // this.handleAlert({
                //   Title: "Upload canceled",
                //   BodyText: "Image uploading has been canceled."
                // });
              } else {
                this.UploadHandlerReloadID = "ABORT";
                i.IsUploading = false;
                this.updateImageState(i);
                this.handleApiError(err);
              }
            });
        }
      }
    }

    if (this.UploadHandlerReloadID === "ABORT") {
      this.UploadHandlerReloadID = null;
      this.UploadHandlerActive = false;
      return;
    } else {
      this.UploadHandlerReloadID = setTimeout(() => this.tryUploadImages(true), 1000);
    }
  }

  updateImageState = image => {
    let images = this.state.Images;
    let imageFinder = images.filter(i => i.ID === image.ID);
    if (imageFinder.length) {
      imageFinder[0] = image;
      this.setState({ Images: images });
    }
  }

  getImageUploadSessionUri = () => {
    const {
      formTemplate,
      formTemplateFieldId,
      isWorkspace,
      documentFolderId,
      organizationId,
      projectId,
      approvalId,
      taskId,
      assetId,
      assetItemId,
    } = this.props;

    // console.log("getImageUploadSessionUri", documentFolderId, approvalId, taskId, assetId, assetItemId);

    let uri;
    if (formTemplate && formTemplateFieldId) {
      uri = GetFormTemplatesPublicFieldImageUploadSessionsPathForApi(formTemplate.ID, 
        formTemplateFieldId);
    } else if (isWorkspace) {
      if (documentFolderId) {
        uri = GetUserOrganizationProjectDocumentFolderImageUploadSessionsPathForApi(organizationId, 
          projectId, documentFolderId);
      } else if (approvalId) {
        if (assetId && assetItemId) {
          if (taskId) {
            uri = GetUserOrganizationProjectApprovalAssetItemTaskImageUploadSessionsPathForApi(
              organizationId, projectId, approvalId, assetId, assetItemId, taskId)
          } else {
            uri = GetUserOrganizationProjectApprovalAssetItemImageUploadSessionsPathForApi(
              organizationId, projectId, approvalId, assetId, assetItemId)
          }
        } else if (taskId) {
          uri = GetUserOrganizationProjectApprovalTaskImageUploadSessionsPathForApi(organizationId, 
            projectId, approvalId, taskId)
        }
      }
      else if (taskId) {
        uri = GetUserOrganizationProjectTaskImageUploadSessionsPathForApi(organizationId, projectId, taskId)
      } 
    }
    else {
      uri = GetImageUploadSessionsPathForApi(organizationId, projectId);
    }
    return uri;
  }

  handleGetNewImageUploadSession = () => {
    this.CancelToken = axios.CancelToken.source();

    const params = {
      documentId: this.props.documentId,
      isAppend: this.props.isAppend,
      documentFolderId: this.props.documentFolderId,
      fieldIDsAndValues_json: (this.props.fieldIDsAndValues) ? JSON.stringify(this.props.fieldIDsAndValues) : undefined,
      uniqueId: this.props.formTemplate && this.props.formTemplate.UniqueID,
    };
    return API.get(this.getImageUploadSessionUri(), { params })
      .then(resp => {
        let ImageUploadSession = resp.data;
        this.setState({ ImageUploadSession });
        return resp.data;
      });
  }

  getImageUploadObjectWithoutImageData = image => {
    let { Base64, ...imageObject } = image;
    return imageObject;
  }

  getImageUploadUri = imageUploadSessionId => {
    const {
      formTemplate,
      formTemplateFieldId,
      isWorkspace,
      documentFolderId,
      organizationId,
      projectId,
      approvalId,
      taskId,
      assetId,
      assetItemId,
    } = this.props;

    // console.log("getImageUploadUri", documentFolderId, approvalId, taskId, assetId, assetItemId);

    let uri;
    if (formTemplate && formTemplateFieldId) {
      uri = GetFormTemplatesPublicFieldImageUploadSessionImagesPathForApi(formTemplate.ID, 
        formTemplateFieldId, imageUploadSessionId);
    } else if (isWorkspace) {
      if (documentFolderId) {
        uri = GetUserOrganizationProjectDocumentFolderImageUploadSessionImagesPathForApi(organizationId, 
          projectId, documentFolderId, imageUploadSessionId);
      } else if (approvalId) {
        if (assetId && assetItemId) {
          if (taskId) {
            uri = GetUserOrganizationProjectApprovalAssetItemTaskImageUploadSessionImagesPathForApi(
              organizationId, projectId, approvalId, assetId, assetItemId, taskId, imageUploadSessionId);
          } else {
            uri = GetUserOrganizationProjectApprovalAssetItemImageUploadSessionImagesPathForApi(
              organizationId, projectId, approvalId, assetId, assetItemId, imageUploadSessionId);
          }
        } else if (taskId) {
          uri = GetUserOrganizationProjectApprovalTaskImageUploadSessionImagesPathForApi(organizationId, 
              projectId, approvalId, taskId, imageUploadSessionId);
        }
      } else if (taskId) {
        uri = GetUserOrganizationProjectTaskImageUploadSessionImagesPathForApi(organizationId, 
            projectId, taskId, imageUploadSessionId);
      }
    } else {
      uri = GetImageUploadSessionImagesPathForApi(organizationId, projectId, imageUploadSessionId);
    }
    return uri;
  }

  handleFinalizeImageUploadSession = () => {
    if (this.state.Images.filter(i => !i.IsUploaded).length) {
      if (this.UploadHandlerReloadID !== "ABORT") {
        this.tryUploadImages();
      }
      if (this.AwaitingUploadCompletionDialogClosedByUser) {
        this.AwaitingUploadCompletionDialogClosedByUser = false;
        return;
      }
      this.handleSetShowAwaitingUploadCompletionDialogVisibility(true);
      if (this.UploadHandlerReloadID !== "ABORT") {
        this.FinalizeImageUploadSessionID = setTimeout(this.handleFinalizeImageUploadSession, 1000);
      }
      return;
    }
    this.handleSetShowAwaitingUploadCompletionDialogVisibility(false);

    this.setState({ShowProgressIndicatorImmediately: true,});

    // console.log("Finalize images", this.state.Images.length);

    const imageUploadsForSubmission = this.state.Images.map(i => this.getImageUploadObjectWithoutImageData(i));

    if (this.props.onComplete) {
      this.props.onComplete(this.state.ImageUploadSession.ID, imageUploadsForSubmission);
    }

    const doPostFinalizationSteps = () => {
      this.UploadHandlerReloadID = "ABORT";
      this.setState({
        ShowProgressIndicatorImmediately: false,
        Images: [],
        SelectedImageIds: [],
        ImageUploadSession: null,
      });
      this.handleAlert({
        Title:"Upload complete",
        BodyContent: (!this.props.skipFinalization) ? (
          <div>
            {this.props.completionMessage || "Uploads are being processed and will be available momentarily."}
          </div>    
        ) : null,
        DialogWidth: "xs",
        CloseCallback: (this.props.closeAfterFirstSubmit && this.props.onClose) ? () => this.props.onClose() : undefined,
      })
    };

    if (this.props.skipFinalization) {
      doPostFinalizationSteps();
    } else {
      const params = {
        taskId: this.props.taskId,
        assetId: this.props.assetId,
        assetItemId: this.props.assetItemId,
      }
      API.put(this.getImageUploadUri(this.state.ImageUploadSession.ID), imageUploadsForSubmission, { params })
        .then(resp => {
          doPostFinalizationSteps();
        })
        .catch(err => {
          this.handleApiError(err);
        });
    }
  }

  handleApiError = err => {
    if (err && typeof err === "string") {
      err = {
        closeCallback: () => this.setState({ApiError: null}),
        response: {
          status: 500,
          data: {
          message: err.toString(),
          },
        },
      };
    }
    this.setState({
      ApiError: err, 
      ShowProgressIndicator: false, 
      ShowProgressIndicatorImmediately: false,
    });
    if (this.props.onApiError) {
      this.props.onApiError(err);
    }
  }

  handleAlert = details => {
    this.setState({ Alert: details });
    if (this.props.onAlert) {
      this.props.onAlert(details);
    }
  }

  handleActionDrawerVisibilityChange = () => {
    this.setState({ImageCardReRenderDate: new Date()});
  }

  componentDidMount() {
    // this.testDeviceConnection();
    this.handleBeginScanNew();
  }

  componentWillUnmount() {
    clearTimeout(this.UploadHandlerReloadID);
    clearTimeout(this.FinalizeImageUploadSessionID);
    this.UploadHandlerActive = false;
  }

  componentDidUpdate(prevProps) {
    if (this.props.beginScanNewKey !== prevProps.beginScanNewKey) {
      this.handleBeginScanNew();
    }
  }

  render() {
    const {
      Images,
      SelectedImageIds,
      ImageCardReRenderDate,
      ShowDeleteImagesConfirmation,
      ShowDialogProgressIndicator,
      ShowProgressIndicator,
      ShowProgressIndicatorImmediately,
      ShowImageDialog,
      ShowImageDialog_Image,
      ShowDeviceCaptureControlDialog,
      ShowAwaitingUploadCompletionDialog,
      ShowConnectionIssueDialog,
      ApiError,
      Alert,
    } = this.state;
    const { 
      classes,
      theme,
      showFab,
      returnContentOnly,
      isAppend,
    } = this.props;

    // console.log("deviceCapture", this.props.documentFolderId,
    //   this.props.approvalId,
    //   this.props.taskId,
    //   this.props.assetId,
    //   this.props.assetItemId);

    let deleteImagesConfirmationDialogDetails = {
      Open:ShowDeleteImagesConfirmation,
      ShowProgressIndicator:ShowDialogProgressIndicator,
      IsConfirmation:true,
      Title:"Delete selected images?",
      BodyText:"This action cannot be undone.",
      BodyClassName:"warning",
      CancelCallback:() => this.handleSetDeleteImageConfirmationVisibility(false),
      CloseCallback:() => this.handleSetDeleteImageConfirmationVisibility(false),
      ConfirmCallback:this.handleDeleteImages,
    };

    let showConnectionIssueDialogDetails = {
      Open:ShowConnectionIssueDialog,
      // ShowProgressIndicator:ShowDialogProgressIndicator,
      Title:"Capture Agent not detected",
      DialogWidth: "xs",
      IsConfirmation:true,
      BodyContent:(
        <Grid container direction="column" spacing={2}>
          <Grid item>
            Download and install Capture Agent to interact with your TWAIN device.
          </Grid>
        </Grid>
      ),
      CancelCallback:() => this.handleSetShowConnectionIssueDialogVisibility(false),
      CloseCallback:() => this.handleSetShowConnectionIssueDialogVisibility(false),
      CancelLabel: "CLOSE",
      ConfirmButton: (
        <Button
          onClick={this.handleDownloadCaptureAgent}>
          DOWNLOAD
        </Button>
      ),
    };

    let awaitingUploadCompletionDialogDetails = {
      Open:ShowAwaitingUploadCompletionDialog,
      // ShowProgressIndicator:ShowDialogProgressIndicator,
      Title:"Completing upload...",
      BodyText:`Images remaining: ${Images.filter(i => !i.IsUploaded).length}`,
      CloseCallback:() => {
        this.AwaitingUploadCompletionDialogClosedByUser = true;
        this.handleSetShowAwaitingUploadCompletionDialogVisibility(false);
      },
    };

    let showImageDialogDetails = {
      Open: false,
    };
    if (ShowImageDialog_Image) {
      let w = document.body.scrollWidth - 112;
      let imageComponent = GetImageComponent(ShowImageDialog_Image, w);
      showImageDialogDetails = {
        Open:ShowImageDialog,
        // Title:"",
        BodyContent: (
          <div
            onClick={() => this.handleSetShowImageDialogVisibility(false)}
            style={{
              width: `${w}px`,
              overflow: "hidden",
              marginTop:-8,
              marginBottom:-8,
            }}
          >
            {imageComponent}
          </div>
        ),
        DialogWidth: "xl",
        CloseCallback:() => this.handleSetShowImageDialogVisibility(false),
      };
    }

    let getDocumentHeader = docNumber => (!isAppend) ? (
      <Grid item xs={12} key={`k_doc${docNumber}`} className={classes.docHeader}>
        <Typography variant="body2">
          Document {docNumber}
        </Typography>
      </Grid>
    ) : null;

    let imageGridItems = [];
    let docNumber = 0;
    if (Images && Images.length) {
      imageGridItems.push(getDocumentHeader(++docNumber));
      let index = 0;
      Images.forEach(i => {
        imageGridItems.push(
          <Grid item
            xs={6}
            sm={4}
            md={3}
            lg={2}
            xl={2}
            key={`image_${i.ID}`}
            style={{position:"relative"}}>
            <ImageCard Image={i}
              onStartMoveImageCard={this.handleStartMoveImageCard}
              onEndMoveImageCard={this.handleEndMoveImageCard}
              onAddEndMoveCallback={this.handleAddEndMoveCallback}
              onMoveImageCard={sourceImage => this.handleMoveImageCard(sourceImage, i)}
              onAbortMoveImageCard={this.handleAbortMoveImageCard}
              onDropImageCard={() => this.handleDropImageCard(i)}
              onAction={actionType => this.handleAction(actionType, i.ID)}
              onSelect={() => this.handleSelectImage(i.ID)}
              selected={SelectedImageIds.indexOf(i.ID) > -1}
              onCardAction={() => this.handleAction(ImageActionType.ZoomIn, i.ID)}
              index={index++}
              reRenderDate={ImageCardReRenderDate}
              isAppend={isAppend}
            />
          </Grid>
        );
        // If the next item is a document break, fill the rest of the current row
        if (Images[index] && Images[index].IsDocumentBreak) {
          imageGridItems.push(getDocumentHeader(++docNumber));
        }
      });
    }

    let numSelected = SelectedImageIds.length;
    let indeterminate = numSelected > 0 && Images && numSelected < Images.length;
    let allSelected = numSelected > 0 && Images && numSelected === Images.length;
    let selectAllComponent = null;
    if (Images && Images.length) {
      selectAllComponent = (
        <Tooltip title="Select">
          <Checkbox
            color="secondary"
            indeterminate={indeterminate}
            checked={indeterminate || allSelected}
            onChange={this.handleSelectAllImages}
          />
        </Tooltip>
      );
    }

    let infoGridItems = [];
    if (Images && Images.length) {
      let docFinder = Images.filter(i => i.IsDocumentBreak);
      if (docFinder.length > 1) {
        infoGridItems.push((<Grid item key="grid_docCount">{NumberWithSeparators(docFinder.length)} documents</Grid>));
      }
      infoGridItems.push((<Grid item key="grid_pageCount">{`${NumberWithSeparators(Images.length)} page${Images.length > 1 ? "s" : ""}`}</Grid>));
    }
    if (SelectedImageIds.length) {
      infoGridItems.push((<Grid item key="grid_selectedCount">{NumberWithSeparators(SelectedImageIds.length)} selected</Grid>));
    }

    let infoGrid = (infoGridItems.length)
      ? (
        <Grid container spacing={2} wrap="nowrap" className={classes.infoGrid}>
          {infoGridItems}
        </Grid>
      )
      : null;

    let toolButtons = (Images && Images.length) ? (
      <div className={classes.toolButtons}>
        <Button variant="contained" size="small"
          onClick={this.handleFinalizeImageUploadSession}>
          {(isAppend) ? "SUBMIT" : "SUBMIT BATCH"}
        </Button>
      </div>
    ) : null;

    let toolHeader = (Images && Images.length) ? (
      <div className={classes.toolHeader}>
        {toolButtons}
        <div className={classes.toolInfo}>
          {infoGrid}
          {selectAllComponent}
        </div>
      </div>
    ) : null;

    const actionListItems = [
      <ListItem key="action_rotateLeft" button onClick={() => this.handleAction(ImageActionType.RotateLeft)}>
        <ListItemIcon>
          {ImageActionType.RotateLeft.Icon}
        </ListItemIcon>
        <ListItemText primary={ImageActionType.RotateLeft.Label} />
      </ListItem>,
      <ListItem key="action_rotateRight" button onClick={() => this.handleAction(ImageActionType.RotateRight)}>
        <ListItemIcon>
          {ImageActionType.RotateRight.Icon}
        </ListItemIcon>
        <ListItemText primary={ImageActionType.RotateRight.Label} />
      </ListItem>,
      <ListItem key="action_delete" button onClick={() => this.handleAction(ImageActionType.Delete)}>
        <ListItemIcon>
          {ImageActionType.Delete.Icon}
        </ListItemIcon>
        <ListItemText primary={ImageActionType.Delete.Label} />
      </ListItem>
    ];

    const internalProgressIndicator = (returnContentOnly
      && (ShowProgressIndicator || ShowProgressIndicatorImmediately))
      ? ( <ProgressIndicator constrained showImmediately={ShowProgressIndicatorImmediately} /> )
      : null;

    const fab = (showFab)
      ? (
        <Fab
          color={theme.palette.type === "dark" ? "default" : "primary"}
          aria-label="Scan new"
          className={classes.fab}
          onClick={this.handleBeginScanNew}>
          <AddIcon />
        </Fab>
      ) : null;

    let content = (
      <div className={classes.root}>
        {internalProgressIndicator}
        <MultiUseDialog Details={deleteImagesConfirmationDialogDetails} />
        <MultiUseDialog Details={showConnectionIssueDialogDetails} />
        <MultiUseDialog Details={awaitingUploadCompletionDialogDetails} />
        <MultiUseDialog Details={showImageDialogDetails} />
        <DeviceCaptureControlDialog
          open={ShowDeviceCaptureControlDialog}
          onAddImage={this.handleAddImage}
          closeCallback={() => this.handleSetShowDeviceCaptureControlDialogVisibility(false)}
          onApiError={this.handleApiError}
          onAlert={this.handleAlert}
          isAppend={isAppend}
        />
       
        {fab}

        <div className={classes.toolHeaderWithContent}>
          {toolHeader}
          <div className={classes.content}>
            <div className={classes.imageGridContainer}>
              <Grid
                spacing={2}
                container
              >
                {imageGridItems}
              </Grid>
            </div>
            <ActionDrawer
              actionListItems={actionListItems}
              allowDrawer={SelectedImageIds.length > 0}
              onVisibilityChange={this.handleActionDrawerVisibilityChange}
            />
          </div>
        </div>        
      </div>
    );

    if (returnContentOnly) {
      return content;
    }

    return (
      <UiCore title={(isAppend) ? "Append to document" : "Scan document(s)"}
        apiError={ApiError}
        alert={Alert}
        showProgressIndicator={ShowProgressIndicator}
        showProgressIndicatorImmediately={ShowProgressIndicatorImmediately}
        content={content}
        hideThemeSwitcher
      />
    );
  }
}

DeviceCapture.propTypes = {
  classes: PropTypes.object.isRequired,
  organizationId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  fieldIDsAndValues: PropTypes.object,
  taskId: PropTypes.string,
  documentId: PropTypes.string,
  approvalId: PropTypes.string,
  assetId: PropTypes.string,
  assetItemId: PropTypes.string,
  formTemplate: PropTypes.object,
  formTemplateFieldId: PropTypes.string,
  skipFinalization: PropTypes.bool,
  closeAfterFirstSubmit: PropTypes.bool,
  onComplete: PropTypes.func,
  completionMessage: PropTypes.string,
  onClose: PropTypes.func,
  documentFolderId: PropTypes.string,
  isWorkspace: PropTypes.bool,
  isAppend: PropTypes.bool,
  onApiError: PropTypes.func,
  onAlert: PropTypes.func,
  showFab: PropTypes.bool,
  beginScanNewKey: PropTypes.object,
  returnContentOnly: PropTypes.bool,
};

export default withStyles(styles, {withTheme: true})(DeviceCapture);