import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import SelectControl from './SelectControl'

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import ProgressIndicator from './ProgressIndicator';
import { GlobalContext } from '../Context/Global.context';
import * as twainCaptureApi from '../Util/twainCaptureApi';

const styles = theme => ({
});

const rankIncrement = 1000;

class DeviceCaptureControlDialog extends React.Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);

    this.state = {
      open: props.open,
      CaptureInProgress: false,
      ImagesCaptured: 0,
      CurrentRank: 0,
      DeviceNames: [],
      DeviceResolutions: [],
      DeviceSizes: [],
      DevicePixelTypes: [],
      DeviceInputSources: [],
      DeviceDuplexTypes: [],
      SelectedTwainDevice: null,
      DisableTwainDeviceSoftware: null,
      ShowProgressIndicator: false,
      ShowProgressIndicatorImmediately: false,
    }
  }

  handleClose = () => {
    setTimeout(() => this.handleStop(), 250);
    if (this.props.closeCallback) {
      this.props.closeCallback();
    }
  }

  responseHasErrorMessage = resp => {
    let d = resp.data;
    if (d && d.ErrorMessage) {
      this.handleApiError(d.ErrorMessage);
      this.handleClose();
      return true;
    }
    return false;
  }

  getNewTwainDevice = () => {
    return {
      DeviceSourceName: "",
      Size: "",
      Resolution: "",
      PixelType: "",
      InputSource: "",
      DuplexType: "",
    };
  }

  getDevices = () => {
    this.setState({ShowProgressIndicatorImmediately: true});
    twainCaptureApi.GetDevices()
      .then(resp => {
        if (this.responseHasErrorMessage(resp)) {
          return null;
        }
        let d = resp.data;
        let firstDevice = d.Device_Dict[0];
        this.setState({
          DeviceNames: d.Device_Dict,
        });
        let deviceChanged = false;
        let SelectedTwainDevice = (this.context && this.context.UserPreferences && this.context.UserPreferences.SelectedTwainDevice)
          ? this.context.UserPreferences.SelectedTwainDevice
          : this.getNewTwainDevice();
        if (!this.context || !this.context.UserPreferences.SelectedTwainDevice
          || !d.Device_Dict.filter(d => d.Key === this.context.UserPreferences.SelectedTwainDevice.DeviceSourceName).length) {
          deviceChanged = true;
          SelectedTwainDevice.DeviceSourceName = firstDevice.Key;
          if (this.context) {
            this.context.SaveUserPreferences_SelectedTwainDevice(SelectedTwainDevice);
          }
        }
        let DisableTwainDeviceSoftware = (this.context && this.context.UserPreferences)
          ? this.context.UserPreferences.DisableTwainDeviceSoftware
          : true;
        this.setState({
          SelectedTwainDevice,
          DisableTwainDeviceSoftware,
        });
        this.getDeviceCapabilities(SelectedTwainDevice.DeviceSourceName, deviceChanged);
      })
      .catch(err => {
        this.handleApiError(err);
      });
  }

  getDeviceCapabilities = (DeviceSourceName, deviceChanged) => {
    this.setState({ShowProgressIndicatorImmediately: true});
    twainCaptureApi.GetDeviceCapabilities(DeviceSourceName)
      .then(resp => {
        if (this.responseHasErrorMessage(resp)) {
          return null;
        }
        let d = resp.data;
        this.setState({
          DeviceSizes: d.Size_Dict,
          DeviceResolutions: d.Resolution_Dict,
          DevicePixelTypes: d.PixelType_Dict,
          DeviceInputSources: d.InputSource_Dict,
          DeviceDuplexTypes: d.DuplexType_Dict,
        });
        if (deviceChanged) {
          const SelectedTwainDevice = {
            DeviceSourceName,
            Size: (d.Size_Dict && d.Size_Dict.length) ? d.Size_Dict[0].Key : "",
            Resolution: (d.Resolution_Dict && d.Resolution_Dict.length) ? d.Resolution_Dict[0].Key : "",
            PixelType: (d.PixelType_Dict && d.PixelType_Dict.length) ? d.PixelType_Dict[0].Key : "",
            InputSource: (d.InputSource_Dict && d.InputSource_Dict.length) ? d.InputSource_Dict[0].Key : "",
            DuplexType: (d.DuplexType_Dict && d.DuplexType_Dict.length) ? d.DuplexType_Dict[0].Key : "",
          };
          this.setState({SelectedTwainDevice});
          if (this.context) {
            this.context.SaveUserPreferences_SelectedTwainDevice(SelectedTwainDevice);
          }
        }
        this.setState({ShowProgressIndicatorImmediately: false});
      })
      .catch(err => {
        this.handleApiError(err);
      });
  }

  beginScan = () => {
    twainCaptureApi.BeginScan(this.state.SelectedTwainDevice, !this.state.DisableTwainDeviceSoftware)
      .then(resp => {
        if (this.responseHasErrorMessage(resp)) {
          return null;
        }
        this.getNextImage();
      })
      .catch(err => {
        this.handleApiError(err);
      });
  }

  cancelScan = () => {
    twainCaptureApi.CancelScan()
      .then(resp => {
        if (this.responseHasErrorMessage(resp)) {
          return null;
        }
      })
      .catch(err => {
        this.handleApiError(err);
      });
  }

  getNextImage = () => {
    twainCaptureApi.GetNextImage()
      .then(resp => {
        if (this.responseHasErrorMessage(resp)) {
          return null;
        }
        let d = resp.data;
        if (d.IsCancelled) {
          return null;
        }
        let rank = rankIncrement + this.state.CurrentRank;
        let image = {
          ID: rank, // ID is used internally
          Rank: rank,
          ContentType: `image/${d.ImageFormat}`,
          Base64: d.ImgBase64,
          Width: d.Width,
          Height: d.Height,
          RotationRequested: 0,
          IsDocumentBreak: !this.props.isAppend && !this.state.ImagesCaptured,
          SignedUrl: null,
          ObjectName: null,
          IsUploading: false,
          IsUploaded: false,
          UploadProgressPercentComplete: 0,
        };
        this.setState({
          CurrentRank : rank,
          ImagesCaptured: 1 + this.state.ImagesCaptured,
        });
        this.props.onAddImage(image);
        if (!d.IsLastImage) {
          this.getNextImage();
        } else {
          this.handleClose();
        }
      })
      .catch(err => {
        this.handleApiError(err);
      });
  }

  handleStart = () => {
  	this.setState({
	  	CaptureInProgress: true,
	  	ImagesCaptured: 0,
	  });
  	
  	this.beginScan();
  }

  handleStop = () => {
  	if (this.state.CaptureInProgress) {
      this.cancelScan();
    }
    this.setState({
  		CaptureInProgress: false,
	  });
  }

  handleApiError = err => {
    // Remove the selected device if there was an error to avoid being unable to select a different device.
    const SelectedTwainDevice = this.getNewTwainDevice();
    this.setState({
      SelectedTwainDevice,
      ShowProgressIndicator: false,
      ShowProgressIndicatorImmediately: false,
    });
    if (this.context) {
      this.context.SaveUserPreferences_SelectedTwainDevice(SelectedTwainDevice);
    }
    this.props.onApiError(err);
  }

  handleUpdateProperty(propertyName, string) {
    let SelectedTwainDevice = {...this.state.SelectedTwainDevice};
    SelectedTwainDevice[propertyName] = string;
    this.setState({SelectedTwainDevice});
    if (this.context) {
      this.context.SaveUserPreferences_SelectedTwainDevice(SelectedTwainDevice);
    }

    if (propertyName === "DeviceSourceName") {
      this.getDeviceCapabilities(string, true);
    }
  }

  handleDisableTwainDeviceSoftwareChanged = e => {
    const DisableTwainDeviceSoftware = !e.target.checked;
    this.setState({DisableTwainDeviceSoftware});
    if (this.context) {
      this.context.SaveUserPreferences_DisableTwainDeviceSoftware(DisableTwainDeviceSoftware);
    }
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps) {
    if (typeof this.props.open !== "undefined"
      && prevProps.open !== this.props.open) {
      if (this.props.open) {
        this.setState({ShowProgressIndicatorImmediately: true});
        if (this.context) {
          this.context.GetUserPreferences()
            .then(() => {
              this.getDevices();
            });
        } else {
          this.getDevices();
        }
      }
      this.setState({open: this.props.open !== false});
    }
  }

  render() {
    const {
      open,
      CaptureInProgress,
      ImagesCaptured,
      DeviceNames,
      DeviceResolutions,
      DeviceSizes,
      DevicePixelTypes,
      DeviceInputSources,
      DeviceDuplexTypes,
      SelectedTwainDevice,
      DisableTwainDeviceSoftware,
      ShowProgressIndicator,
      ShowProgressIndicatorImmediately,
    } = this.state;
    const { 
      // classes,
      theme,
    } = this.props;

		let startStopButton = null;
		let deviceGrid = null;
    if (CaptureInProgress) {
    	startStopButton = (
    		<Button onClick={this.handleStop}>
          STOP
        </Button>
  		);

  		deviceGrid = (ImagesCaptured === 0)
        ? (
        <Typography variant="h6">
          Initiating capture...
        </Typography>
        )
        : (
    			<Typography variant="h6">
  					Images captured: {ImagesCaptured}
    			</Typography>
  			);
    } else {
	    if (!DeviceNames) {
	    	return null;
	    }

	    startStopButton = (
    		<Button onClick={this.handleStart}>
          START
        </Button>
  		);

      let selectedTwainDevice = (SelectedTwainDevice)
        ? SelectedTwainDevice
        : {
          DeviceSourceName: "",
          Size: "",
          Resolution: "",
          PixelType: "",
          InputSource: "",
          DuplexType: "",
        };

  		const deviceNameOptions = DeviceNames.map(i => ({ label: i.Value, value: i.Key }));
	    let deviceNameGridItem = (
	      <Grid item xs={12}>
	        <SelectControl
	        	id="selectName"
	        	hideEmpty
	          label="Device"
	          // forceShrinkLabel
	          options={deviceNameOptions} 
	          value={selectedTwainDevice.DeviceSourceName}
	          onValueChange={key => this.handleUpdateProperty("DeviceSourceName", key)} />
	      </Grid>
	    );

      let isFolderDevice = selectedTwainDevice.DeviceSourceName === "___Folder_Device___";

      let useDeviceSoftwareGridItem = (!isFolderDevice)
        ? (
          <Grid item xs={12} style={{alignSelf:"center"}}>
            <FormControlLabel
              control={
                <Switch color="secondary" checked={!DisableTwainDeviceSoftware}
                  onChange={this.handleDisableTwainDeviceSoftwareChanged}
                />
              }
              label="Use device software" />
          </Grid>
        ) : null;

			const deviceSizeOptions = DeviceSizes.map(i => ({ label: i.Value, value: i.Key }));
	    let deviceSizesGridItem = (
	      <Grid key="d_size" item xs={6}>
	        <SelectControl
	        	id="selectSize"
	          label="Paper Size"
	          // forceShrinkLabel
	          options={deviceSizeOptions} 
	          value={selectedTwainDevice.Size}
	          onValueChange={size => this.handleUpdateProperty("Size", size)} />
	      </Grid>
	    );

	    const deviceResolutionOptions = DeviceResolutions.map(i => ({ label: i.Value, value: i.Key }));
	    let deviceResolutionsGridItem = (
	      <Grid key="d_res" item xs={6}>
	        <SelectControl
	        	id="selectResolution"
	          label="Resolution"
	          // forceShrinkLabel
	          options={deviceResolutionOptions} 
	          value={selectedTwainDevice.Resolution}
	          onValueChange={resolution => this.handleUpdateProperty("Resolution", resolution)} />
	      </Grid>
	    );

	    const devicePixelTypeOptions = DevicePixelTypes.map(i => ({ label: i.Value, value: i.Key }));
	    let devicePixelTypesGridItem = (
	      <Grid key="d_pixel" item xs={6}>
	        <SelectControl
	        	id="selectPixelType"
	          label="Pixel Type"
	          // forceShrinkLabel
	          options={devicePixelTypeOptions} 
	          value={selectedTwainDevice.PixelType}
	          onValueChange={pixelType => this.handleUpdateProperty("PixelType", pixelType)} />
	      </Grid>
	    );

	    const deviceInputSourceOptions = DeviceInputSources.map(i => ({ label: i.Value, value: i.Key }));
	    let deviceInputSourcesGridItem = (
	      <Grid key="d_input" item xs={6}>
	        <SelectControl
	        	id="selectInputSource"
	          label="Input Source"
	          // forceShrinkLabel
	          options={deviceInputSourceOptions} 
	          value={selectedTwainDevice.InputSource}
	          onValueChange={inputSource => this.handleUpdateProperty("InputSource", inputSource)} />
	      </Grid>
	    );

	    const deviceDuplexTypeOptions = DeviceDuplexTypes.map(i => ({ label: i.Value, value: i.Key }));
	    let deviceDuplexTypesGridItem = (
	      <Grid key="d_duplex" item xs={6}>
	        <SelectControl
	        	id="selectDuplexType"
	          label="Duplex Type"
	          // forceShrinkLabel
	          options={deviceDuplexTypeOptions} 
	          value={selectedTwainDevice.DuplexType}
	          onValueChange={duplexType => this.handleUpdateProperty("DuplexType", duplexType)} />
	      </Grid>
	    );

      let deviceGridItems = [];
      if (DisableTwainDeviceSoftware && !isFolderDevice) {
        deviceGridItems = [
          deviceSizesGridItem,
          deviceResolutionsGridItem,
          devicePixelTypesGridItem,
          deviceInputSourcesGridItem,
          deviceDuplexTypesGridItem,
        ];
      }
	    deviceGrid = (
		    <Grid container spacing={2} style={{marginTop:theme.spacing(1)}}>
					{deviceNameGridItem}
          {useDeviceSoftwareGridItem}
					{deviceGridItems}
	      </Grid>
      );
	  }

    let dialogActions = (
      <DialogActions>
        <Button onClick={this.handleClose}>
          CLOSE
        </Button>
        {startStopButton}
      </DialogActions>
    );

    let progressIndicator = null;
    if (ShowProgressIndicator || ShowProgressIndicatorImmediately) {
      progressIndicator = (
        <ProgressIndicator showImmediately={ShowProgressIndicatorImmediately} />
      );
    }

    return (
      <Dialog
        fullWidth
        maxWidth="xs"
        open={open}
        onClose={this.handleClose}
        // aria-labelledby="dialog-title"
        // aria-describedby="dialog-description">
        // <DialogTitle id="dialog-title">
        //   <span>{selectedTwainDevice.Name}</span>
        // </DialogTitle>
        >
        <DialogContent>
          {progressIndicator}
          {deviceGrid}
        </DialogContent>
        {dialogActions}
      </Dialog>
    );
  }
}

DeviceCaptureControlDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  isAppend: PropTypes.bool,
};

export default withStyles(styles, {withTheme: true})(DeviceCaptureControlDialog);