const DocumentEventType = [
	{
		Type: "Revision",
		Label: "Revision",
	},
	{
		Type: "Append",
		Label: "Append",
	},
	{
		Type: "NameChange",
		Label: "Name change",
	},
	{
		Type: "FieldsCommit",
		Label: "Fields",
	},
	{
		Type: "TagsChange",
		Label: "Tags",
	},
	{
		Type: "Comment",
		Label: "Comment",
	},
	{
		Type: "DocumentApprovalAction",
		Label: "Approval action",
	},
	{
		Type: "GroupApprovalAction",
		Label: "Group-approval action",
	},
	{
		Type: "RecycleBinAction",
		Label: "Recycle-bin action",
	},
	{
		Type: "SignatureSessionEvent",
		Label: "Signature event",
	},
	{
		Type: "FormSubmissionEvent",
		Label: "Form-submission event",
	},
	{
		Type: "DocumentFolderChange",
		Label: "Folder change",
	},
];

export default DocumentEventType;